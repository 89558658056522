import React, { useRef } from "react";
import emailsjs from "@emailjs/browser";

import Button from "../ui/Button";
import Keys from "../../assets/configKeys.json";
import CloseMark from "../ui/CloseMark";

function ContactForm({ closeForm, openSuccess }) {
  const form = useRef();

  const closeContactForm = () => closeForm(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailsjs
      .sendForm(
        Keys[0].serviceId,
        Keys[0].templateId,
        form.current,
        Keys[0].publicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          closeForm(false);
          openSuccess(true);
        },
        (error) => {
          console.log(error.text);
          console.log("ups, something went wrong");
        }
      );
  };

  return (
    <div className="contact-form">
      <form className="form" ref={form} onSubmit={sendEmail}>
        <CloseMark onClick={closeContactForm} />

        <input
          type="text"
          name="user_name"
          placeholder="Name"
          className="form__input"
          id="user-name"
          required
        />
        <label htmlFor="user-name" className="form__label form__label--name">
          Name
        </label>

        <input
          type="email"
          name="user_email"
          placeholder="Email"
          className="form__input"
          id="user-mail"
          required
        />
        <label htmlFor="user-mail" className="form__label form__label--mail">
          Email
        </label>

        <textarea
          name="message"
          id="message-text"
          placeholder="your message..."
          className="form__text"
          required
        ></textarea>
        <label
          htmlFor="message-text"
          className="form__label form__label--message"
        >
          Message
        </label>

        <div className="form__buttons">
          <Button className={"btn--dark"} type={"submit"}>
            Send{" "}
            <span>
              <svg className="form__buttons--send">
                <use xlinkHref="/img/sprite_2.svg#icon-paper-plane"></use>
              </svg>
            </span>
          </Button>
          <Button
            className={"btn--light"}
            type={"button"}
            onClick={closeContactForm}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
