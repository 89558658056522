import React, { useState } from "react";
import Button from "../ui/Button";
import ContactForm from "./ContactForm";
import Success from "../ui/Success";

function Contact() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const openForm = () => setShowContactForm(true);
  const closeForm = () => setShowContactForm(false);

  const openSuccess = () => setShowSuccess(true);
  const closeSuccess = () => setShowSuccess(false);

  return (
    <div className="section contact" id="contact">
      <p className="contact__text">
        Let's contact if you think I'm a good fit for a position!
      </p>
      <Button className={"btn--dark"} onClick={openForm} type={"button"}>
        Send a message
      </Button>

      {showContactForm && (
        <ContactForm closeForm={closeForm} openSuccess={openSuccess} />
      )}

      {showSuccess && <Success onClick={closeSuccess} />}
    </div>
  );
}

export default Contact;
