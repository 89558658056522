import React from "react";

function Logo() {
  return (
    <a href="/" alt="logo" className="logo">
      A
    </a>
  );
}

export default Logo;
