import React from "react";

import CloseMark from "./CloseMark";

function Success({ onClick }) {
  const closeContactForm = () => onClick(true);
  return (
    <div className="success">
      <div className="success__card">
        <CloseMark onClick={closeContactForm} />
        <img
          src={process.env.PUBLIC_URL + "/img/parrot.gif"}
          alt="Dancing parrot"
          className="success__gif"
        />
        <p className="success__text">
          Your message has been sent! <br /> Thank you very much!
        </p>
      </div>
    </div>
  );
}

export default Success;
