import React from "react";

function SideLine() {
  return (
    <div className="side">
      <div className="side__dot side__dot--1"></div>
      <div className="side__dot side__dot--2"></div>
      <div className="side__dot side__dot--3"></div>
      <div className="side__dot side__dot--4"></div>
      <div className="side__dot side__dot--5"></div>
    </div>
  );
}

export default SideLine;
