import React from "react";

import Socials from "../ui/Socials";

function Footer() {
  return (
    <div className="footer" id="footer">
      <Socials />
      <p className="footer__legal">&copy; 2024</p>
      <p className="footer__legal">Anita Skumát</p>
    </div>
  );
}

export default Footer;
