import React from "react";

function Header() {
  return (
    <div className="header" id="header">
      <div className="header__text">
        <h1 className="heading-1 header__text--first">Hi there, I'm Anita,</h1>
        <h1 className="heading-1 header__text--second">
          a junior front-end developer.
        </h1>
      </div>
      <img
        src={process.env.PUBLIC_URL + "/img/me.png"}
        alt="Anita"
        className="header__img"
      />
    </div>
  );
}

export default Header;
