import { Tooltip, Zoom } from "@mui/material";
import React from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import Experiences from "../../assets/experience.json";

function Experience() {
  return (
    <div className="section experience" id="experience">
      <h2 className="heading-2">Previous roles & experiences</h2>

      <VerticalTimeline>
        {Experiences.map((experience) => {
          return (
            <VerticalTimelineElement
              key={experience.id}
              date={experience.period}
              dateClassName={"experience__date"}
              contentClassName={"experience__content"}
              contentStyle={{
                boxShadow: "0px 2px 6px -1px #bd9f8c",
              }}
            >
              <h3 className=" heading-3 vertical-timeline-element-title">
                {experience.role}
              </h3>
              <h4 className="heading-4 vertical-timeline-element-subtitle">
                {experience.company}
              </h4>
              <h5 className="heading-5 vertical-timeline-element-subtitle">
                {experience.field}
              </h5>
              <p id="experience__description">{experience.description}</p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>

      {/* Codecool */}
      {/* <div className="experience__main">
          <h3 className="heading-3">Student</h3>
          <p>Codecool</p>
        </div>
        <div className="experience__period">0.4 y</div>
        <ul className="experience__task-list">
          <li>
            practicing <mark>frontend</mark> technologies
          </li>
          <li>
            working on <mark>solo & team projects</mark>
          </li>
          <li>
            presenting during <mark>demos</mark>
          </li>
        </ul> */}

      {/* BSAM GMC */}
      {/* <div className="experience__main">
          <h3 className="heading-3">Senior Global Metrics Coordinator</h3>
          <p>ExxonMobil Hungary</p>
        </div>
        <div className="experience__period">1.0 y</div>
        <ul className="experience__task-list">
          <li>
            <mark>collaborating</mark> with colleagues from{" "}
            <mark>all units</mark> {"(globally)"}
          </li>
          <li>
            preparing{" "}
            <mark>
              monthly
              <Tooltip
                title={
                  <p
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    "Key Perfomance Indicators"
                  </p>
                }
                enterTouchDelay={0}
                arrow
                TransitionComponent={Zoom}
              >
                <span> KPI</span>
              </Tooltip>{" "}
              letters
            </mark>
          </li>
          <li>
            <mark>training</mark> users & new members
          </li>
          <li>
            handling monthly <mark>reporting</mark> & yearly{" "}
            <mark>targets</mark>
          </li>
          <li>
            <mark>coordinating & supporting</mark> system coordinators
          </li>
        </ul> */}

      {/* BSAM SC */}
      {/* <div className="experience__main">
          <h3 className="heading-3">Senior System Coordinator</h3>
          <p>ExxonMobil Hungary</p>
        </div>
        <div className="experience__period">1.5 y</div>
        <ul className="experience__task-list">
          <li>
            <mark>assisting</mark> colleagues of 2 <mark>American</mark>{" "}
            accounting systems
          </li>
          <li>
            <mark>training</mark> users & new members
          </li>
          <li>
            preparing{" "}
            <mark>
              statistics &
              <Tooltip
                title={
                  <p
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    "Key Perfomance Indicators"
                  </p>
                }
                enterTouchDelay={0}
                arrow
                TransitionComponent={Zoom}
              >
                <span> KPI</span>
              </Tooltip>{" "}
              comments
            </mark>
          </li>
        </ul> */}

      {/* Ministry */}
      {/* <div className="experience__main">
          <h3 className="heading-3">Project Finance Manager</h3>
          <p>Ministry of Finance</p>
          <p className="experience__side-note">
            {"(in the same project of the previous role)"}
          </p>
        </div>
        <div className="experience__period">3.5 y</div>
        <ul className="experience__task-list">
          <li>
            <mark>cooperating</mark> with project <mark>associates</mark> &
            other supporting <mark>departments</mark>
          </li>
          <li>
            <mark>creating & handling</mark> a scheme for follow-up & reclaiming
            management's costs
          </li>
          <li>
            <mark>managing</mark> subsidy amount <mark>distribution</mark>
          </li>
          <li>
            <mark>preparing contracts</mark> for partner Counties
          </li>
        </ul> */}

      {/* Project Associate */}
      {/* <div className="experience__main">
          <h3 className="heading-3">Project Associate</h3>
          <p>Government Office of Békés County</p>
        </div>
        <div className="experience__period">2.5 y</div>
        <ul className="experience__task-list">
          <li>
            assisting <mark>employers & employees</mark>
          </li>
          <li>
            following-up with <mark>clients</mark>
          </li>
          <li>
            preparing wage subsidy <mark>contracts</mark>
          </li>
          <li>
            calculating <mark>monthly refunds</mark>
          </li>
        </ul> */}
    </div>
  );
}

export default Experience;
