import React from "react";

function Socials() {
  return (
    <div className="social">
      <a href="https://github.com/matka00" target="blank">
        <svg className="social-icon">
          <use xlinkHref="/img/sprite.svg#icon-github"></use>
        </svg>
      </a>
      <a href="https://linkedin.com/in/anitaskumat/" target="blank">
        <svg className="social-icon">
          <use xlinkHref="/img/sprite.svg#icon-linkedin"></use>
        </svg>
      </a>
    </div>
  );
}

export default Socials;
