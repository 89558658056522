import React from "react";

import AboutList from "../../assets/about.json";

function About() {
  return (
    <div className="section about" id="about">
      <h2 className="heading-2">About Me</h2>

      <div className="about__container">
        {AboutList.map((aboutItem) => {
          return (
            <div key={aboutItem.id} className={"about__item about__item--" + `${aboutItem.id}`}>
              <p className="about__text about__text--question">
                {aboutItem.question}
              </p>
              <p className="about__text about__text--answer">
                {aboutItem.answer}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default About;
