import React from "react";

import PetList from "../../assets/petprojects.json";
import { Tooltip, Zoom } from "@mui/material";

function Projects() {
  return (
    <div className="section projects" id="projects">
      <h2 className="heading-2">Projects</h2>

      <div className="projects__container">
        {PetList.map((petProject) => {
          return (
            <div className="project" key={petProject.id}>
              <a
                href={petProject.path}
                className="project__link--img"
                target="blank"
              >
                <figure className="project__figure">
                  <img
                    src={process.env.PUBLIC_URL + `${petProject.pictureUrl}`}
                    alt={petProject.title}
                    className="project__img"
                  />
                  <figcaption className="project__caption">View</figcaption>
                </figure>
              </a>
              <a
                href={petProject.path}
                className="project__link--title"
                target="blank"
              >
                <h3 className="project__title">{petProject.title}</h3>
              </a>
              <p className="project__label">{petProject.label}</p>
              <p className="project__desc">{petProject.description}</p>

              <ul className="project__tools">
                {petProject.technologies.map((technologie) => {
                  return (
                    <Tooltip
                      key={technologie.toolName}
                      title={
                        <p
                          style={{
                            fontSize: "1.2rem",
                          }}
                        >
                          {technologie.toolName}
                        </p>
                      }
                      enterTouchDelay={0}
                      arrow
                      TransitionComponent={Zoom}
                    >
                      <img
                        className="project__tools--logo"
                        src={
                          process.env.PUBLIC_URL +
                          `${technologie.toolPictureUrl}`
                        }
                        alt={technologie.toolName}
                      />
                    </Tooltip>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
