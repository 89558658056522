import React from "react";

function CloseMark({ onClick }) {
  return (
    <svg className="close" onClick={onClick}>
      <use xlinkHref="/img/sprite_2.svg#icon-cross"></use>
    </svg>
  );
}

export default CloseMark;
