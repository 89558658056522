import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";

import Logo from "../ui/Logo";

function Navbar() {
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  return (
    <div className="navbar">
      <Logo />

      <div className="navbar__menu-icon" onClick={handleClick}>
        <svg className="navbar__menu-icon--item">
          <use
            xlinkHref={
              click
                ? "/img/sprite_2.svg#icon-cross"
                : "/img/sprite_2.svg#icon-menu"
            }
          ></use>
        </svg>
      </div>

      <ul className={click ? "navbar__menu--active" : "navbar__menu"}>
        <li className="navbar__item">
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={800}
            className="navbar__item--link"
            onClick={closeMobileMenu}
          >
            About Me
          </Link>
        </li>

        <li className="navbar__item">
          <Link
            to="skills"
            spy={true}
            smooth={true}
            offset={0}
            duration={1000}
            className="navbar__item--link"
            onClick={closeMobileMenu}
          >
            Skills
          </Link>
        </li>

        <li className="navbar__item">
          <Link
            to="projects"
            spy={true}
            smooth={true}
            offset={0}
            duration={1200}
            className="navbar__item--link"
            onClick={closeMobileMenu}
          >
            Projects
          </Link>
        </li>

        <li className="navbar__item">
          <Link
            to="experience"
            spy={true}
            smooth={true}
            offset={0}
            duration={1400}
            className="navbar__item--link"
            onClick={closeMobileMenu}
          >
            Experience
          </Link>
        </li>

        <li className="navbar__item">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={2000}
            className="navbar__item--link"
            onClick={closeMobileMenu}
          >
            Contact
          </Link>
        </li>
      </ul>

      <Link to="header" smooth={true} offset={0} duration={2400}>
        <svg className={scrolled ? "scroll-up" : "scroll-up--hidden"}>
          <use xlinkHref="/img/sprite_2.svg#icon-chevron-with-circle-up"></use>
        </svg>
      </Link>
    </div>
  );
}

export default Navbar;
