import React from "react";

import ToolList from "../../assets/toolList.json";
import { Tooltip, Zoom } from "@mui/material";

function Skills() {
  return (
    <div className="section skills" id="skills">
      <h2 className="heading-2">Technologies I'm using</h2>

      <div className="skills__tools">
        {ToolList.map((tool) => {
          return (
            <Tooltip
              key={tool.id}
              title={
                <p
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  {tool.title}
                </p>
              }
              enterTouchDelay={0}
              arrow
              TransitionComponent={Zoom}
            >
              <img
                className="skills__tool-img"
                src={process.env.PUBLIC_URL + `${tool.pictureUrl}`}
                alt={tool.title}
              />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

export default Skills;
